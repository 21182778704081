import sum from 'lodash/sum'
import {
  action,
  computed,
  isObservableArray,
  makeObservable,
  observable,
} from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { guid, setModelValue } from '../common/utils'
import AppStore from './AppStore'
import { RouteStore } from './RouteStore'
import StockStore from './StockStore'

export const SELL_STAGE_ROUTES = 1
export const SELL_STAGE_CONTRACTORS = 2
export const SELL_STAGE_PRODUCTS = 3
export const SELL_STAGE_PAYMENT = 4
export const SELL_STAGE_PRINT = 5

function _validNumeric(value, digits = 3) {
  value = value ? parseFloat(value) : 0
  return isFinite(value) ? +value.toFixed(digits) : 0
}

function _productAmount(product) {
  product = { ...product }
  product.quantity = _validNumeric(product.quantity)
  product.return = _validNumeric(product.return)
  product.defective = _validNumeric(product.defective)
  product.amount =
    _validNumeric(product.price, 4) *
    (product.quantity - (product.return + product.defective))
  return product
}

function _productQuantityFilter(product) {
  return product.quantity > 0 || product.return > 0 || product.defective > 0
}

export class SellStore {
  @observable is_loading = 0
  @observable is_ready = false
  @observable stage = SELL_STAGE_ROUTES
  @observable day_filter = null
  @observable search_contractor = ''
  @observable search_product = ''
  @observable input_column = 'quantity'
  @observable confirm_pay = false
  @observable routes = observable.array()
  @observable route = null
  @observable contractors = observable.array()
  @observable contractor = null
  @observable done_contractors = observable.map({})
  @observable products = observable.array()
  @observable pay_amount = null
  @observable pay_debt = null
  @observable show_scanner = false
  @observable prices_index = -1
  @observable number_waybill = ''
  @observable common_price_type = null

  @observable scrollPosition = 0
  @observable sell_id = null

  @observable settings = {}
  @observable data = {}

  @observable send_invoice_status = null

  constructor() {
    makeObservable(this)
  }

  @computed
  get pay_total() {
    if (this.stage < SELL_STAGE_PAYMENT) return
    return SellStore.countTotal(
      this.common_price_type
        ? this.products.filter(this.filterSellCommonPriceType)
        : this.products,
      this.settings,
    )
  }

  @computed
  get pay_diff() {
    let { pay_amount, pay_total, pay_debt } = this
    pay_amount = parseFloat(pay_amount || '0')
    pay_total = parseFloat(pay_total || '0')
    pay_debt = parseFloat(pay_debt || '0')
    return SellStore.roundAmount(
      pay_amount - (pay_total + pay_debt),
      this.settings,
    )
  }

  @action
  initState() {
    this.input_column = 'quantity'
    this.confirm_pay = false
    this.prices_index = -1

    let stage = SELL_STAGE_ROUTES
    if (
      this.stage > SELL_STAGE_ROUTES &&
      this.route &&
      this.routes.length > 0
    ) {
      stage = SELL_STAGE_CONTRACTORS
    } else {
      this.loadRoutes()
      this.route = null
    }
    if (stage === SELL_STAGE_CONTRACTORS) {
      if (
        this.stage > SELL_STAGE_CONTRACTORS &&
        this.contractor &&
        this.contractors.length > 0
      ) {
        stage = SELL_STAGE_PRODUCTS
        this.input_column = 'quantity'
      } else {
        this.loadContractors()
        this.contractor = null
      }
    }
    if (stage === SELL_STAGE_PRODUCTS) {
      if (
        this.stage > SELL_STAGE_PRODUCTS &&
        this.validateQuantity() &&
        this.sell_products_grouped_with_amount.length > 0
      ) {
        stage = SELL_STAGE_PAYMENT
        this.loadSettings()
        this.loadDebts()
      } else {
        this.loadStock()
        this.pay_amount = null
        this.pay_debt = null
      }
    }
    this.stage = stage
    this.is_ready = true
  }

  @action
  clearStage() {
    this.is_ready = false
  }

  @action
  addCoordinates(latitude, longitude) {
    console.log('latitude', latitude)
    console.log('longitude', longitude)
    if (!this.data) return
    !this.data?.latitude && setModelValue(this.data, 'latitude', null)
    !this.data?.longitude && setModelValue(this.data, 'longitude', null)
    setModelValue(this.data, 'latitude', latitude)
    setModelValue(this.data, 'longitude', longitude)
  }

  @action
  incrLoading = () => {
    this.is_loading++
  }

  @action
  decrLoading = () => {
    this.is_loading--
  }

  @action
  prevStage = app => {
    if (this.is_loading) {
      app.showWarning('Идет загрузка, подождите.')
      return
    }
    this.stage--
    if (this.stage < SELL_STAGE_PAYMENT) {
      this.prices_index = -1
      this.pay_amount = null
      this.pay_debt = null
    }
    if (this.stage < SELL_STAGE_PRODUCTS) {
      this.common_price_type = null
      this.input_column = 'quantity'
      this.contractor = null
      this.products.clear()
    }
    if (this.stage < SELL_STAGE_CONTRACTORS) {
      this.route = null
      this.contractors.clear()
    }
  }

  @action
  nextStage = app => {
    switch (this.stage + 1) {
      case SELL_STAGE_CONTRACTORS:
        if (!this.route) {
          app.showWarning('Выберите маршрут!')
          return
        }
        this.loadContractors()
        break
      case SELL_STAGE_PRODUCTS:
        if (!this.contractor) {
          app.showWarning('Выберите контрагента!')
          return
        }
        this.loadStock()
        break
      case SELL_STAGE_PAYMENT:
        if (!this.validateQuantity())
          return app.showWarning('Проверьте количество!')
        if (app.user_info.branch.data.restrict_sell_over_stock === true) {
          for (const p of this.products) {
            if (SellStore.totalQuantity(p) > p.count) {
              return app.showWarning(
                `Количество продукта ${p.nomenclature} на складе ${app.user_info.branch?.name} не может быть меньше нуля`,
              )
            }
          }
        }

        this.confirm_pay = false
        this.loadSettings()
        this.loadDebts()
        break
      case SELL_STAGE_PRINT:
        if (this.is_loading) return
        if (
          (!this.pay_amount && this.pay_amount !== 0) ||
          this.isNaN(this.pay_amount)
        )
          return app.showWarning('Укажите сумму оплаты!')
        if (!this.confirm_pay) {
          this.confirm_pay = true
          return
        }
        this.postSalesItem()
        return
      default:
        return
    }
    this.stage++
  }

  getContractorDoc = async () => {
    try {
      const { data } = await requester.get(
        `/contractor/contractor-docs/${this.contractor?.id}`,
      )
      return data.item
    } catch {
      return null
    }
  }

  async updateData(app, file) {
    try {
      if (this.sell_id) {
        const path = `/sales/${this.sell_id}`
        const {
          data: { item },
        } = await requester.get(path)
        const form = new FormData()
        form.append('file', file)
        const resp = await requester.put(path, form)
        await localDB.post(path, { ...item, file_waybill: resp.data.link })
        app.showSuccess('Данные сохранены')
      }
    } catch (e) {
      const is_network_error =
        e.toString().toLowerCase().includes('network error') ||
        e.result ||
        (e.data && e.data.result)
      if (!is_network_error) {
        app.showWarning(e.toString())
      }
    }
  }

  resetSellPrint() {
    this.sell_id = null
  }

  isNaN = amount =>
    amount
      ? (typeof amount === 'string' && amount.includes(',')) || isNaN(amount)
      : false

  @action
  toggleDayFilter = () => {
    this.day_filter = this.day_filter ? null : moment().isoWeekday()
    this.loadRoutes()
  }

  @action
  switchInput = () => {
    this.input_column = {
      quantity: 'return',
      return: 'defective',
      defective: 'quantity',
    }[this.input_column]
  }

  @action
  clearConfirmPay = () => {
    this.confirm_pay = false
  }

  loadRoutes = () => {
    this.incrLoading()
    SellStore.getRoutes(this.day_filter)
      .then(this.setRoutes)
      .finally(this.decrLoading)
  }

  static async getRoutes(day_filter) {
    const data = await localDB.get('/routes', {}, 'name')
    let routes = []
    for (let r of data ? data.list : [])
      r.data.contractors.length > 0 &&
        (!day_filter || r.data.days.includes(day_filter)) &&
        routes.push({
          ...r,
          days_string: r.data.days.map(RouteStore.mapDays).join(', '),
        })
    return routes
  }

  @action
  setRoutes = routes => {
    this.routes.replace(routes)
  }

  @action
  onSelectRoute(route, app) {
    this.route = route
    this.scrollPosition = 0
    this.nextStage(app)
  }

  loadContractors = () => {
    this.incrLoading()
    SellStore.getContractors(this.route.data.contractors)
      .then(this.setContractors)
      .finally(this.decrLoading)
  }

  static async getContractors(contractor_ids) {
    const debts = await SellStore.getDebts(contractor_ids)
    let contractors = []
    for (let contractor_id of contractor_ids) {
      const contractor = await localDB.get('/contractors/' + contractor_id)
      contractor &&
        contractors.push({
          ...contractor.item,
          debt: debts[contractor_id] || 0,
        })
    }
    return contractors
  }

  @action
  setContractors = contractors => {
    if (
      contractors.length <= this.scrollPosition > 0 &&
      this.scrollPosition > 0
    )
      this.scrollPosition = contractors.length > 0 ? contractors.length - 1 : 0
    this.contractors.replace(contractors)
  }

  @action
  onSelectContractor(contractor, app, index) {
    this.contractor = contractor
    this.nextStage(app)
    this.scrollPosition = index > -1 ? index : 0
  }

  loadSettings = () => {
    this.incrLoading()
    SellStore.getSettings().then(this.setSettings).finally(this.decrLoading)
  }

  static async getSettings() {
    let settings = await localDB.get(
      '/settings',
      { 'filter[group]': 'global' },
      'key',
    )
    if (!settings) settings = { list: [] }
    return settings.list.reduce((s, item) => {
      if (!s[item.group]) s[item.group] = {}
      s[item.group][item.key] = item.value
      return s
    }, {})
  }

  @action setSettings = settings => {
    this.settings = settings
  }

  loadDebts = () => {
    this.incrLoading()
    SellStore.getDebts([this.contractor.id])
      .then(this.setDebt)
      .finally(this.decrLoading)
  }

  static async getDebts(contractor_ids) {
    let debts = await localDB.get('/contractordebts', {}, null, true)
    if (!debts) debts = { list: [] }
    const { ContractorDebtStore } = await import('./ContractorDebtStore')
    debts.list = ContractorDebtStore.countDebts(debts.list, contractor_ids)
    const stores = await ContractorDebtStore.getOfflineStores(contractor_ids)
    for (let item of debts.list) {
      if (stores[item.id]) {
        item.sale_amount = 1 * item.sale_amount + stores[item.id].total
        item.wod_amount = 1 * item.wod_amount + stores[item.id].wod
        item.debt_amount = 1 * item.debt_amount + stores[item.id].debt
        item.diff = item.debt_amount - item.wod_amount
        delete stores[item.id]
      }
      if (Object.keys(stores).length === 0) {
        break
      }
    }

    for (let id of Object.keys(stores)) {
      debts.list.push({
        id: id,
        contractor_id: stores[id].contractor_id,
        rec_user: stores[id].rec_user,
        contractor: null,
        sale_amount: stores[id].total,
        wod_amount: stores[id].wod,
        debt_amount: stores[id].debt,
        diff: stores[id].debt - stores[id].wod,
      })
    }

    let debts_dict = {}
    for (let item of debts.list) {
      if (!debts_dict[item.contractor_id]) debts_dict[item.contractor_id] = 0
      debts_dict[item.contractor_id] += item.diff
    }
    return debts_dict
  }

  @action
  setDebt = debts => {
    if (this.contractor) this.pay_debt = debts[this.contractor.id] || 0
  }

  loadSales = () => {
    this.incrLoading()
    SellStore.getSales().then(this.setSales).finally(this.decrLoading)
  }

  static async getSales() {
    const today = moment().format('YYYY-MM-DD')
    const data = await localDB.get(
      '/sales',
      {
        'filter[start_from]': today,
        'filter[end_to]': today,
      },
      'rec_date desc',
      true,
    )
    const sales = data && data.list ? data.list : []
    let contractor_sales = {}
    for (let sale of sales) {
      const contractor_id = `${sale.contractor_id}`
      if (
        sale.contractor_id &&
        !sale.data.imported &&
        !contractor_sales[contractor_id]
      ) {
        contractor_sales[contractor_id] = sale.id
      }
    }
    return contractor_sales
  }

  @action
  setSales = contractor_sales => {
    this.done_contractors.replace(contractor_sales)
  }

  loadStock = () => {
    this.incrLoading()
    SellStore.getStock().then(this.setStock).finally(this.decrLoading)
  }

  static async getStock() {
    const stores = await StockStore.getOfflineStores()
    const branch_id = AppStore.user_info ? AppStore.user_info.branch_id : 0
    let stock = await localDB.get('/stock', { 'filter[branch_id]': branch_id })
    let products = []
    if (!stock) stock = { list: [] }
    for (let item of stock.list) {
      if (!item.branch_id || !item.nomenclature_id) continue
      if (stores[item.nomenclature_id]) {
        stores[item.nomenclature_id].count += parseFloat(item.count || '0')
      } else {
        stores[item.nomenclature_id] = { count: parseFloat(item.count || '0') }
      }
    }
    let templates = {}
    for (let nomenclature_id of Object.keys(stores)) {
      await SellStore.appendProducts(
        products,
        templates,
        Number(nomenclature_id),
        stores[nomenclature_id].count,
      )
    }

    products.sort(SellStore.sortProducts)

    return products
  }

  static sortProducts(a, b) {
    if (b.nomenclature > a.nomenclature) {
      return -1
    }
    if (a.nomenclature > b.nomenclature) {
      return 1
    }
    return 0
  }

  @action
  setStock = products => {
    let new_products = products.map(product => {
      let old_product = this.products.find(
        f => f.nomenclature_id === product.nomenclature_id,
      )
      if (old_product) {
        return {
          ...product,
          quantity: old_product.quantity,
          return: old_product.return,
          defective: old_product.defective,
        }
      }
      return product
    })

    this.products.replace(new_products)
    if (this.sell_products_filtered.length === 0) {
      this.search_product = ''
      this.common_price_type = null
    }
  }

  @action
  togglePrices = index => {
    this.prices_index = this.prices_index === index ? -1 : index
  }

  @action
  setPrice(item) {
    const { prices, price_type } = item
    let price = prices.find(p => p.name === price_type)
    if (price) item.price = parseFloat(parseFloat(price.price).toFixed(4))
    if (isNaN(item.price)) item.price = null
  }

  @action
  setCommonPrice = price_type => {
    if (!this.common_price_type) return
    this.products.forEach(item => {
      if (this.filterSellCommonPriceType(item)) {
        item.price_type = this.common_price_type
        this.setPrice(item)
      }
    })
  }

  @computed
  get sell_products() {
    return this.products
      .map(product =>
        !product.access_sub ? [product] : [product, ...product.sub_products],
      )
      .flat()
  }

  @computed
  get sell_products_filtered() {
    let products = this.sell_products
    if (this.search_product)
      products = products.filter(this.filterSellSearchProduct)
    if (this.common_price_type)
      products = products.filter(this.filterSellCommonPriceType)
    return products
  }

  @computed
  get sell_products_category_units_total_quantity() {
    return this.sell_products_filtered.reduce(
      this.sellProductsCategoryUnitsCountReducer,
      {},
    )
  }

  @computed
  get sell_products_grouped() {
    return this.sellProductsCategorySort(
      this.sell_products_filtered.reduce(this.sellProductsCategoryReducer, {}),
    )
  }

  @computed
  get sell_products_common_price_type_filtered() {
    let products = this.sell_products
    if (this.common_price_type)
      products = products.filter(this.filterSellCommonPriceType)
    return products.map(_productAmount).filter(_productQuantityFilter)
  }

  @computed
  get sell_products_category_units_total_quantity_with_amount() {
    return this.sell_products_common_price_type_filtered.reduce(
      this.sellProductsCategoryUnitsQuantityReducer,
      {},
    )
  }

  @computed
  get sell_products_category_total_amount() {
    return this.sell_products_common_price_type_filtered.reduce(
      (categories, product) => {
        const { category_id, amount } = product
        if (!categories[category_id]) categories[category_id] = 0
        categories[category_id] += amount
        return categories
      },
      {},
    )
  }

  @computed
  get sell_products_grouped_with_amount() {
    return this.sellProductsCategorySort(
      this.sell_products_common_price_type_filtered.reduce(
        this.sellProductsCategoryReducer,
        {},
      ),
    )
  }

  unitsReducer = (categories, product) => {
    const { category_id, unit_id, unit } = product
    if (!categories[category_id]) categories[category_id] = []
    let unit_item = categories[category_id].find(
      unit => unit.unit_id === unit_id,
    )
    if (!unit_item) {
      unit_item = { unit_id, unit, quantity: 0, return: 0, defective: 0 }
      categories[category_id].push(unit_item)
    }
    return unit_item
  }

  sellProductsCategoryUnitsCountReducer = (categories, product) => {
    const unit = this.unitsReducer(categories, product)
    unit.quantity += this.validNumeric(product.count)
    return categories
  }

  sellProductsCategoryUnitsQuantityReducer = (categories, product) => {
    const unit = this.unitsReducer(categories, product)
    unit.quantity += this.validNumeric(product.quantity)
    unit.return += this.validNumeric(product.return)
    unit.defective += this.validNumeric(product.defective)
    return categories
  }

  sellProductsCategoryReducer = (categories, product) => {
    const { category_id, category } = product
    if (!categories[category_id]) {
      categories[category_id] = {
        key: category_id,
        category_id,
        category,
        data: [],
      }
    }
    categories[category_id].data.push(product)

    return categories
  }

  sellProductsCategorySort(categories) {
    return Object.values(categories)
      .sort(this.sellSortByCategory)
      .map((item, index) => ({
        ...item,
        index: (index + 1) * 10000,
      }))
  }

  sellSortByCategory(a, b) {
    return a.category.localeCompare(b.category)
  }

  @computed
  get picker_prices() {
    const prices = this.products.reduce((total, item) => {
      item.prices.forEach(price => {
        if (!total[price.name]) {
          total[price.name] = price.display_name
        }
      })
      return total
    }, {})

    return Object.entries(prices).map(([value, text]) => ({ value, text }))
  }

  static filterSellSearchProducts(item, common_price_type) {
    if (!common_price_type)
      return (
        (item.quantity && parseFloat(item.quantity) !== 0) ||
        (item.return && parseFloat(item.return) !== 0) ||
        (item.defective && parseFloat(item.defective) !== 0)
      )

    return (
      item.data.price_type === common_price_type &&
      ((item.quantity && parseFloat(item.quantity) !== 0) ||
        (item.return && parseFloat(item.return) !== 0) ||
        (item.defective && parseFloat(item.defective) !== 0))
    )
  }

  static accessSubProduct(sub_branches) {
    if (!sub_branches || sub_branches.length === 0) return false
    const upper_branch_ids = AppStore.upper_branch_ids
    return sub_branches.some(branch => upper_branch_ids.includes(branch.id))
  }

  validateQuantity() {
    for (const p of this.products) {
      if (
        this.isNaN(p.quantity) ||
        this.isNaN(p.return) ||
        this.isNaN(p.defective)
      )
        return false
    }
    return true
  }

  static zIfNaN2Float(n) {
    return parseFloat(!n || isNaN(n) ? 0 : n)
  }

  static totalQuantity(item) {
    let quantity = 0,
      ret_quantity = 0,
      defective = 0
    if (item.quantity) quantity = SellStore.zIfNaN2Float(item.quantity)
    if (item.return) ret_quantity = SellStore.zIfNaN2Float(item.return)
    if (item.defective) defective = SellStore.zIfNaN2Float(item.defective)
    const count = quantity - (ret_quantity + defective)
    return isNaN(count) ? 0 : count
  }

  static countWithSubProduct(p) {
    let quantity = parseFloat(p.quantity || '0')
    let sub_quantity = 0
    if (p.access_sub) {
      sub_quantity = p.sub_products.reduce(
        (t, item) => t + parseFloat(item.quantity || '0'),
        0,
      )
    }
    const count = quantity + sub_quantity
    return isNaN(count) ? 0 : count
  }

  static countItemAmount(p, with_sub = false) {
    if (!with_sub || !p.access_sub) {
      return SellStore.amountProduct(p)
    }
    return sum([p, ...p.sub_products].map(p => SellStore.countItemAmount(p)))
  }

  static amountProduct(p) {
    const product = _productAmount(p)
    if (!_productQuantityFilter(product)) return 0
    return product.amount
  }

  static roundAmount(amount, settings) {
    const total_amount_rounding = parseFloat(
      settings?.global?.sales_total_amount_rounding || '1',
    )
    amount = Math.round(amount / total_amount_rounding) * total_amount_rounding
    return parseFloat(amount.toFixed(4))
  }

  static countTotal(products, settings, discount_amount = 0, tax_amount = 0) {
    const total = sum(products.map(p => SellStore.countItemAmount(p, true)))
    return SellStore.roundAmount(total - discount_amount + tax_amount, settings)
  }

  postSalesItem() {
    this.incrLoading()
    this._postSalesItem()
      .then(this.postDataSuccess)
      .catch(this.showWarning)
      .finally(this.decrLoading)
  }

  async _postSalesItem() {
    if (!this.sell_id) this.sell_id = guid()
    return await localDB.post(`/sales/add`, this.sales_item_data)
  }

  @computed get sales_item_data() {
    return {
      id: this.sell_id,
      remote_id: this.sell_id,
      sale_status: 'new',
      contractor_id: this.contractor.id,
      pay_amount: this.pay_amount || null,
      number_waybill: this.number_waybill || '',
      data: {
        history_pay_amount: [
          {
            rec_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            pay_amount: this.pay_amount || 0,
            contractor_id: this.contractor.id,
          },
        ],
        products: this.products
          .map(item =>
            SellStore.mapProductsPost(item, false, this.common_price_type),
          )
          .filter(item =>
            SellStore.filterSellSearchProducts(item, this.common_price_type),
          ),
        comment: '',
        latitude: this.data.latitude || null,
        longitude: this.data.longitude || null,
      },
      branch_id: AppStore.user_info?.branch_id || null,
      currency_id: AppStore.user_info?.branch.currency_id || null,
    }
  }

  static mapProductsPost(item, is_sub = false, common_price_type = null) {
    const product = {
      nomenclature: item.nomenclature,
      nomenclature_id: item.nomenclature_id,
      price: item.price || null,
      min_price: item.min_price || null,
      quantity: item.quantity || null,
      return: item.return || null,
      defective: item.defective || null,
      history: [
        {
          rec_date: moment().format('YYYY-MM-DD HH:mm:ss'),
          price: item.price || 0,
          min_price: item.min_price || 0,
          quantity: item.quantity,
          return: item.return,
          defective: item.defective,
        },
      ],
      data: {
        price_type: item.price_type,
      },
    }

    if (is_sub) {
      product.is_sub = true
      product.unit = item.unit
    } else {
      product.access_sub = item.access_sub
      product.sub_products = []
      if (item.access_sub) {
        product.sub_products = item.sub_products
          .map(item => SellStore.mapProductsPost(item, true, common_price_type))
          .filter(item =>
            SellStore.filterSellSearchProducts(item, common_price_type),
          )
      }
    }
    return product
  }

  @action
  postDataSuccess = data => {
    this.input_column = 'quantity'
    if (data && data.id) {
      this.sell_id = data.id
      if (this.contractor && this.contractor.id) {
        const contractor_id = `${this.contractor.id}`
        this.done_contractors.set(contractor_id, data.id)
      }
    }
    if (this.route) {
      this.loadContractors()
    }
    if (this.contractor) {
      this.loadStock()
      this.loadSettings()
      this.loadDebts()
    }
    if (this.stage === SELL_STAGE_PAYMENT) {
      this.stage++
    }
  }

  showWarning = e => {
    e && !e.result && AppStore.showWarning(e.message)
  }

  @action
  clearDataSuccess = () => {
    this.common_price_type = null
    this.sell_id = null
    this.stage = SELL_STAGE_CONTRACTORS
    this.products.map(p => {
      p.count -= p.quantity ? parseFloat(p.quantity) : 0
      p.quantity = p.return = p.defective = null
    })
    this.pay_amount = null
    this.number_waybill = ''
    this.send_invoice_status = null
  }

  @action
  sendInvoice = async () => {
    this.send_invoice_status = 'send'
    const d = this.sales_item_data
    const save_data = {
      ...d,
      data: {
        ...d.data,
        send_invoice_status: this.send_invoice_status,
      },
    }
    await localDB.post(`/sales/${this.sell_id}`, save_data)

    if (localDB.hasConnection) {
      await requester.get(`/sale/send-invoice/${this.sell_id}`)
      this.send_invoice_status = 'sent'
      AppStore.showInfo('Отправлено')
    } else {
      AppStore.showInfo('Будет отправлено после синхронизации!')
    }
  }

  addNomenclatures = async nomenclatures => {
    const nids = this.products.map(p => p.nomenclature_id)
    let products = []
    let templates = {}
    for (let n of nomenclatures) {
      if (nids.includes(n.id)) continue
      nids.push(n.id)
      await SellStore.appendProducts(products, templates, n.id, 0)
    }
    products.sort(SellStore.sortProducts)
    this.setProducts(products)
  }

  @action
  setProducts(products) {
    this.products.push(...products)
  }

  @action toggleScanner = () => {
    this.show_scanner = !this.show_scanner
  }

  @action showScanner = () => {
    this.show_scanner = true
  }

  @action hideScanner = () => {
    this.show_scanner = false
  }

  @action onReadQRCode = ({ data: value }) => {
    this.hideScanner()
    if (typeof value === 'string' && value.length > 0) {
      this.number_waybill = value.trim()
    }
  }

  static async appendProducts(products, templates, nomenclature_id, count) {
    let n = await localDB.get(
      '/nomenclatures/' + nomenclature_id,
      {},
      null,
      true,
    )
    n = n ? n.item : null
    if (!n || !n.data.templates || n.data.templates.length === 0) return

    let prices = []
    for (const tid of n.data.templates) {
      if (typeof templates[tid] !== 'undefined') {
        prices.push(...templates[tid])
        continue
      }
      let t = await localDB.get('/templates/' + tid)
      t = t ? t.item : null
      if (t && t.temp_type === 'price' && !t.data.decider && t.data.prices) {
        templates[tid] = t.data.prices
        prices.push(...templates[tid])
      } else {
        templates[tid] = []
      }
    }
    if (prices.length === 0) return
    const sale_prices = SellStore.reducePrices(prices)
    if (sale_prices.length === 0) return
    const standard_price = sale_prices.find(
      p => p.name === 'standart' || p.name === 'standard',
    )
    const min_price = SellStore.minPrice(sale_prices)
    let price, price_type
    if (standard_price) {
      price = parseFloat(parseFloat(standard_price.price).toFixed(4))
      price_type = standard_price.name
    } else {
      price = parseFloat(parseFloat(sale_prices[0].price).toFixed(4))
      price_type = sale_prices[0].name
    }

    let product = {
      access_sub: SellStore.accessSubProduct(n.data.sub_branches),
      nomenclature_id: nomenclature_id,
      nomenclature: n.name,
      unit: n.unit ? n.unit.short : '',
      unit_id: n.unit ? n.unit.id : null,
      category: n.category ? n.category.name : '',
      category_id: n.category ? n.category.id : null,
      count: count,
      quantity: null,
      return: null,
      defective: null,
      price,
      min_price,
      price_type,
      prices: sale_prices,
      sub_products: [],
    }

    if (product.access_sub) {
      const sub_products = []
      for (const sp of n.data.sub_nomenclature || []) {
        let data = await localDB.get(
          '/nomenclatures/' + sp.nomenclature_id,
          {},
          null,
          true,
        )
        let sub_n = data ? data.item : null
        if (!sub_n) return
        sub_products.push({
          nomenclature_id: sub_n.id,
          nomenclature: sub_n.name,
          category_id: sub_n.category ? sub_n.category.id : null,
          category: sub_n.category ? sub_n.category.name : '',
          unit_id: sub_n.unit ? sub_n.unit.id : null,
          unit: sub_n.unit ? sub_n.unit.short : '',
          is_sub: true,
          quantity: null,
          return: null,
          defective: null,
          price,
          price_type,
          min_price,
          prices: sale_prices,
        })
        product.sub_products = sub_products
      }
    }
    products.push(product)
  }

  static reducePrices(prices) {
    const ret_prices = []
    if (Array.isArray(prices) || isObservableArray(prices)) {
      prices
        .reverse()
        .forEach(p =>
          (p.hasOwnProperty('prices') ? p.prices : [p]).forEach(
            price =>
              ret_prices.every(rp => rp.name !== price.name) &&
              ret_prices.push(price),
          ),
        )
    }
    return ret_prices.sort(SellStore.sortPrices)
  }

  static sortPrices(a, b) {
    return b.price - a.price
  }

  static minPrice(prices) {
    const sorted = prices.sort(SellStore.sortPrices)
    const min_price = sorted
      .reverse()
      .find(p => p.name && p.name.toLowerCase().indexOf('market') === -1)
    return min_price
      ? min_price.price
      : sorted.length > 0
      ? sorted[0].price
      : null
  }

  @computed
  get filteredContractors() {
    if (this.search_contractor) {
      return this.contractors.filter(this.filterContractors)
    }
    return this.contractors.slice()
  }

  filterContractors = c => {
    return c.name.toLowerCase().includes(this.search_contractor.toLowerCase())
  }

  filterSellSearchProduct = p =>
    p.nomenclature
      .toLowerCase()
      .trim()
      .includes(this.search_product.toLowerCase().trim())

  filterSellCommonPriceType = p =>
    p.prices.some(price => price.name === this.common_price_type)

  validNumeric(value) {
    value = value ? parseFloat(value) : 0
    return isFinite(value) ? value : 0
  }
}

const store = new SellStore()
export default store
